// Please leave 'en' where it is, as it's our fallback language in case no translation can be found
export const SupportedTranslationLocales: string[] = [
  "en",
  // "af",
  // "ar",
  // "az",
  // "be",
  // "bg",
  // "bn",
  // "bs",
  // "ca",
  // "cs",
  // "da",
  // "de",
  // "el",
  // "en-GB",
  // "en-IN",
  // "eo",
  // "es",
  // "et",
  // "eu",
  // "fi",
  // "fil",
  "fr",
  // "he",
  // "hi",
  // "hr",
  // "hu",
  // "id",
  // "it",
  // "ja",
  // "ka",
  // "km",
  // "kn",
  // "ko",
  // "lv",
  // "ml",
  // "nb",
  // "nl",
  // "nn",
  // "pl",
  // "pt-PT",
  // "pt-BR",
  // "ro",
  // "ru",
  // "si",
  // "sk",
  // "sl",
  // "sr",
  // "sv",
  // "tr",
  // "uk",
  // "vi",
  // "zh-CN",
  // "zh-TW",
];
