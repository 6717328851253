<!-- <app-change-kdf *ngIf="showChangeKdf"></app-change-kdf> -->
<div
  [ngClass]="{ 'tw-mt-6': !showChangeKdf, 'tw-mt-16': showChangeKdf }"
  class="tw-border-0 tw-mb-0 tw-pb-2.5"
>
  <h2 bitTypography="h2">{{ "apiKey" | i18n }}</h2>
</div>
<p bitTypography="body1">
  {{ "userApiKeyDesc" | i18n }}
</p>
<button type="button" bitButton buttonType="secondary" (click)="viewUserApiKey()">
  {{ "viewApiKey" | i18n }}
</button>
<button type="button" bitButton buttonType="secondary" (click)="rotateUserApiKey()">
  {{ "rotateApiKey" | i18n }}
</button>
<ng-template #viewUserApiKeyTemplate></ng-template>
<ng-template #rotateUserApiKeyTemplate></ng-template>
